html {
  height: 100%;
  font-family: 'Nunito', cursive;
}
body {
  height: 100%;  
  min-height: 100vh;
  background-color: rgb(127, 171, 192);
  background-image: url("https://www.transparenttextures.com/patterns/black-thread-light.png");
/* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
  /* background-image: url('/assets/bg.jpg'); */
  /* background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed; */
}
#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.about{
  height: 100%;
  width: 100%;
  background-size: cover;
  background-image: url("https://www.canva.com/design/DAEJj63TsU0/omOIosSBBK7u6jK6Y6aMcA/view?utm_content=DAEJj63TsU0&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink");
}

a:hover{
  text-decoration: none !important;
}

.justify-center{
  display: flex;
  justify-content: center;
}

.carousel {
  background-image: inherit;
  position: absolute;
  margin: 0;
}




.myClass {
  text-align: center;
  justify-content: center;
}

.myClass > a {
  background-color: white;
  color: red;
}
.myClass > a:hover {
  background-color: pink;
}

.fop {
  margin: 25px auto;
  font-family: 'Nunito';
}

.Footer {
  text-align: center;
  background-color: rgb(18, 107, 158);
  color: white;
  width: 100%;
}

.red {
  text-align-last: center;
  text-align: center;
  margin-top: 15%;
  text-shadow: currentColor;
}

.image {
  height: 340;
}
.contact {
  font-family: 'Nunito', cursive;
  color: white;
}

.red span {
  font-family: 'Nunito', cursive;
  color: rgb(255, 255, 255);
}

.red p{
  font-family: 'Nunito', cursive;
  color: white;
}

.red h3 {
  color: white;
  font-family: 'Nunito', cursive;
}

.contact {
  text-align: center;
  margin: 25px;
}

/* .note {
  padding: 10px;
  background: rgba(0,0,0,0.5);
  width: 460px;
  height: 640px;
  margin: 22px;
  float: left;
} */
/* .note h1 {
  font-size: 1.3em;
  font-weight: 500;
  margin-bottom: 6px;
  font-family: 'Lobster', cursive;
  justify-content: center;
  text-align-last: center;
} */

/* .note p {
  font-size: 1.1em;
  margin-bottom: 10px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.note button {
  position: relative;
  float: right;
  margin-right: 10px;
  color: #f5ba13;
  border: none;
  width: 36px;
  height: 36px;
  cursor: pointer;
  outline: none;
} */

form.create-note label {
  font-family: 'Nunito', cursive;
}

form.create-note {
  position: relative;
  width: 30em;
  margin: 20px 0;
  background: #fff;
  padding: 15px;
  border-radius: 7px;
  box-shadow: 0 1px 5px rgb(138, 137, 137);
}




.filter {
  height: 100%;
  text-align: center;
  background-color: #fff;
}

.adding {
  font-family: 'Nunito', cursive;
  position: relative;
  color: white;
  margin-top: 30px;
  text-align-last: center;
}
.error {
  color: red;
}
label h5 {
  color: rgb(41, 102, 138);
}
form.create-note input,
form.create-note textarea {
  width: 100%;
  height: 40px;
  position: relative;
  border-top: none;
  border-left: none;
  border-right: none;
  font-family: 'Nunito', cursive;
  padding: 4px;
  margin-bottom: 10px;
  outline: none;
  font-size: 1.2em;
  resize: none;
}
.create-note-submit  {
  position: relative;
  margin: 10px auto 20px auto;
  background: #156dd1;
  color: #fff;
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.dropdown {
  width: 100%;
  height: 40px;
  position: relative;
  border-top: none;
  color: #41423d;
  border-left: none;
  border-right: none;
  font-family: 'Nunito', cursive;
  padding: 4px;
  margin-bottom: 10px;
  outline: none;
  font-size: 1.2em;
  resize: none;
}

.dropdown2 {
  width: 70%;
  height: 40px;
  position: relative;
  border-top: none;
  color: #41423d;
  border-left: none;
  border-right: none;
  font-family: 'Nunito', cursive;
  padding: 4px;
  margin-bottom: 10px;
  outline: none;
  font-size: 1.2em;
  resize: none;
}

textarea[type = text]:not(.browser-default):focus:not([readonly]),
input[type = text]:not(.browser-default):focus:not([readonly]),
input[type = email]:not(.browser-default):focus:not([readonly]),
input[type = password]:not(.browser-default):focus:not([readonly]),
input[type = url]:not(.browser-default):focus:not([readonly]),
input[type = tel]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]){
  border-color: #00bfff;
}
